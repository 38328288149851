/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@/sass/_text.scss'

Vue.use(Vuetify)

const theme = {
  primary: '#164192', // '#e91e63'
  secondary: '#2E9EF2',
  accent: '#FFA526',
  info: '#87CDF0',
  muted: '#6c757d',
  error: '#F91E14',
  success: '#4CAF50',
  warning: '#FFA526'
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
