

<script>
  import { VCard } from 'vuetify/lib'

  export default {
    name: 'Card',

    extends: VCard,
  }
</script>
