/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import routes from './config/routes.js';
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

import ACLChecker from './acl.js';
ACLChecker.router = router;
router.beforeEach(ACLChecker.checkAuthenticatedUser);

export default router;
