/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import 'chartist/dist/chartist.min.css'

Vue.use(require('vue-chartist'))
