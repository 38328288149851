/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import Vuex from 'vuex'

import authModule from './modules/auth';
import messageModule from './modules/messages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    portal: false,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    }
  },
  actions: {
    // called at boot to reload state from localstorage
    init(store) {
      try {
        store.dispatch('auth/reloadState');
      } catch (e) {
        store.dispatch('clear');
      }
    },
  },

  modules: {
    auth: authModule,
    globalMessages: messageModule,
  }
})
