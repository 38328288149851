/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

let routes = [
	// ======================
	// Blank Layout
	// ======================
	{
		path: '',
		component: () => import('@/views/external/Index'),
		// ======================
		// Theme routes / pages
		// ======================
		children: [
			{
				path: '/',
				redirect: '/login'
			},
			{
				path: '/login',
				name: 'Login',
				index: 1,
				props: true,
				component: () => import('@/views/external/Login'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/otp',
				name: 'Submit OTP',
				component: () => import('@/views/external/Otp'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/forgot-password',
				name: 'Forgot Password',
				component: () => import('@/views/external/ForgotPassword'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/resend-link',
				name: 'Resend Link',
				component: () => import('@/views/external/ResendLink'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/reset-password/:token',
				name: 'Reset Password',
				props: true,
				component: () => import('@/views/external/ResetPassword'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/verify-email/:token',
				name: 'Verify Email',
				props: true,
				component: () => import('@/views/external/VerifyEmail'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/register/',
				name: 'Register',
				index: 2,
				props: true,
				component: () => import('@/views/external/Register'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/social/:strategy',
				name: 'LoginSocialPlatform',
				index: 1,
				props: true,
				component: () => import('@/views/external/Social'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/register/validate/:token',
				name: 'Validate Register',
				index: 3,
				component: () => import('@/views/external/Validate'),
				props: true,
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/logout',
				name: 'Logout',
				index: 4,
				meta: {
					acl: 'private'
				}
			},
			{
				path: '/error404',
				name: 'error404',
				index: 6,
				component: () => import('@/views/external/Error'),
				meta: {
					acl: '*'
				}
			}
		]
	},
	{
		path: '/',
		component: () => import('@/views/internal/Index'),
		meta: {
			acl: 'private'
		},
		children: [
			// Components
			// Dashboard
			{
				name: 'Dashboard',
				path: '/',
				meta: {
					acl: 'private',
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/Dashboard'),
			},
			{
				name: 'User Account',
				path: '/organization/account/',
				meta: {
					acl: 'private',
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Profile',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/organization/Profile'),
			},
			{
				name: 'Users',
				path: '/organization/users/',
				meta: {
					acl: {
						module: 'saas',
						route: '/organization/users',
						method: 'get'
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/organization/Users'),
			},
			{
				name: 'addUser',
				path: '/organization/users/new',
				meta: {
					section: { name: 'organization' },
					acl: {
						module: 'saas',
						route: '/organization/users',
						method: 'put',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							href: 'Users'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/organization/UsersAdd')
			},
			{
				name: 'editUser',
				path: '/organization/users/:id',
				meta: {
					section: { name: 'organization' },
					acl: {
						module: 'saas',
						route: '/organization/users/:id',
						method: 'patch',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							href: 'Users'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/organization/UsersEdit')
			},
			{
				name: 'saaSProjects',
				path: '/projects/saas',
				meta: {
					acl: {
						module: 'saas',
						route: '/project',
						method: 'get'
					},
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false,
							href: 'Dashboard'
						},
						{
							title: 'SAAS Projects',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/projects/Saas'),
			},
			{
				name: 'enterpriseProjects',
				path: '/projects/enterprise',
				meta: {
					acl: {
						module: 'saas',
						route: '/project',
						method: 'get'
					},
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false,
							href: 'Dashboard'
						},
						{
							title: 'Enterprise Project',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/projects/Enterprise'),
			},
			{
				name: 'createProject',
				path: '/projects/create/:type',
				meta: {
					acl: {
						module: 'saas',
						route: '/project/:type',
						method: 'post'
					},
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false,
							href: 'Dashboard'
						},
						{
							title: 'Create Project',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/projects/Create'),
			},
			{
				name: 'editProject',
				path: '/projects/create/:type/:id',
				meta: {
					acl: {
						module: 'saas',
						route: '/project/:id/:mode',
						method: 'put'
					},
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false,
							href: 'Dashboard'
						},
						{
							title: 'Edit Project',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/projects/Edit'),
			},
			{
				name: 'Billing',
				path: '/history',
				meta: {
					acl: {
						module: 'saas',
						route: '/invoice',
						method: 'get'
					},
					breadcrumb: [
						{
							title: 'My Account',
							disabled: false,
							href: 'User Account'
						},
						{
							title: 'Billing',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/orders/Index'),
			},
			{
				name: 'Payment',
				path: '/payment',
				meta: {
					acl: {
						module: 'saas',
						route: '/paymentMethod',
						method: 'get'
					},
					breadcrumb: [
						{
							title: 'My Account',
							disabled: false,
							href: 'User Account'
						},
						{
							title: 'Payment Methods',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/paymentMethods/Index'),
			},
			{
				name: 'addPayment',
				path: '/payment/new',
				meta: {
					acl: {
						module: 'saas',
						route: '/paymentMethod',
						method: 'post'
					},
					breadcrumb: [
						{
							title: 'My Account',
							disabled: false,
							href: 'User Account'
						},
						{
							title: 'Payment Methods',
							disabled: false,
							href: 'Payment'
						},
						{
							'title': 'Add New Card',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/paymentMethods/Add'),
			},
			{
				name: 'editPayment',
				path: '/payment/edit/:id',
				meta: {
					acl: {
						module: 'saas',
						route: '/paymentMethod',
						method: 'post'
					},
					breadcrumb: [
						{
							title: 'My Account',
							disabled: false,
							href: 'User Account'
						},
						{
							title: 'Payment Methods',
							disabled: false,
							href: 'Payment'
						},
						{
							'title': 'Edit Card',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/paymentMethods/Edit'),
			}
		],
	},
	{
		path: '*',
		component: () => import('@/views/external/Index'),
		children: [
			{
				name: '404 Error',
				path: '',
				component: () => import('@/views/external/Error'),
			},
		],
	},
];

module.exports = routes;
