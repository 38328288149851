/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import store from './store/store.js';

const PUBLIC_ACL = ['*', 'public'];

const isPublicRoute = (route) => {
  if (!route.meta || !route.meta.acl) return true;
  if (PUBLIC_ACL.includes(route.meta.acl)) return true;
  return false;
};

const isPrivateRoute = (route) => {
  if (!route.meta || !route.meta.acl) return false;
  if (route.meta.acl === 'private') return true;
  return false;
};

const ACLChecker = {
  user: null,
  userACL: null,
  env: 'portal',

  /**
   * Initialize the ACLChecker
   */
   init: function (route) {
    // init the store first
    store.dispatch('init');
    // init local props
    ACLChecker.user = store.getters['auth/getUser'];
    ACLChecker.userACL = store.getters['auth/getACL'];
    if (route && route.meta && route.meta.acl && route.meta.acl.envCode) {
      ACLChecker.env = route.meta.acl.envCode.toLowerCase();
    } else if (store.getters['env/get']) {
      ACLChecker.env = store.getters['env/get'].value;
    }
  },

  /**
   * check whether the requested permission exists in the User's ACL Schema
   * @param {Object} context
   * @param environment
   * @param module
   * @param section
   * @returns {boolean}
   */
  hasComponentAccess: function (context, environment, module = 'consoleapi', section = 'apis') {
    // init
    ACLChecker.init();
    // defaults
    environment = environment ? environment.toLowerCase() : (ACLChecker.env ? ACLChecker.env.toLowerCase() : 'portal');
    // not authenticated
    if (!ACLChecker.user) return false;
    // empty ACL config object
    if (!context || !context.method || !context.route) return true;
    // missing user acl
    if (!ACLChecker.userACL || !ACLChecker.userACL[environment] || !ACLChecker.userACL[environment][module]) return false;
    
    let routeMethod = context.method.toLowerCase();
    let routeUrl = context.route;
    if (context.module) module = context.module;
    if (context.section) section = context.section;

    let authenticated = false;
    for (let version in ACLChecker.userACL[environment][module]) {
      if (ACLChecker.userACL[environment][module][version][section][routeMethod] &&
        ACLChecker.userACL[environment][module][version][section][routeMethod][routeUrl] &&
        ACLChecker.userACL[environment][module][version][section][routeMethod][routeUrl].access) {
        authenticated = true;
        break;
      }
    }
    return authenticated;
  },

  /**
   * scan the user's acl against the route's acl to determine if he can access this route or not
   * @param route
   * @param environment
   * @param module
   * @param section
   * @returns {Boolean}
   */
  isAuthenticated: function (route, environment, module = 'consoleapi', section = 'apis') {
    // initialize
    ACLChecker.init(route);
    // no route acl, allow everyone
    if (!route.meta || !route.meta.acl) return true;
    if (isPublicRoute(route)) {
      // public route, all users allowed
      return true;
    } else if (isPrivateRoute(route)) {
      // private route, authenticated users allowed
      return ACLChecker.user ? true : false;
    } else {
      // custom acl config object specified
      return ACLChecker.hasComponentAccess(route.meta.acl, environment, module, section);
    }
  },

  /**
   * generates the required messages that should be displayed depending on conditions and criteria
   */
  populateAuthorizationMessages: function () {
    let messages = store.getters['globalMessages/list'];

    // if logout was fired, do not throw unauthorized messages
    let pushMessage = true;
    if (messages && messages.length > 0) {
      messages.forEach((oneMessage) => {
        if (oneMessage.text.includes("User Logout Successfully")) {
          pushMessage = false;
        }

        if (oneMessage.text.includes("Please Login to proceed!")) {
          pushMessage = false;
        }
      });
    }

    if (pushMessage) {
      store.dispatch('globalMessages/push', {
        type: 'error',
        title: "Access Denied",
        text: "Please Login to proceed!"
      });
    }
  },

  /**
   * Check if user is authorized and authenticated to access the requested route.
   * @param to
   * @param from
   * @param next
   * @returns {Promise<*>}
   */
  checkAuthenticatedUser: function (to, from, next) {
    // check if user already authenticated
    const allowed = ACLChecker.isAuthenticated(to);
    // if authenticated and allowed, then proceed
    if (allowed) return next();
    // if not authenticated and route is private, proceed to login
    if (!ACLChecker.user) {
      ACLChecker.populateAuthorizationMessages();
      store.dispatch('auth/clear');
      return next({ name: 'Login' });
    }
    // if authenticated but route access is denied, show error page
    return next({ name: 'error404' });
  }
};

export default ACLChecker;
